<template>
  <el-col
    :span="span"
    :xs="spanMap.xs"
    :sm="spanMap.sm"
    :md="spanMap.md"
    :lg="spanMap.lg"
    :xl="spanMap.xl"
    class="descriptions-item"
  >
    <div class="descriptions-item-content">
      <div class="descriptions-item-label" :style="{ 'width': labelWidth + 'px', 'text-align': 'right' }">{{ label }}</div>
      <div class="descriptions-item-value">
        <slot v-if="$slots.content" name="content" />
        <div v-else :class="classes" :title="value">{{ value }}</div>
      </div>
    </div>
  </el-col>
</template>

<script>
export default {
  name: 'ElDescriptionItem',
  props: {
    spanMap: {
      type: Object,
      required: false,
      default: () => { return { } }
    },
    span: {
      type: Number,
      required: false,
      default: 6
    },
    label: {
      required: true
    },
    value: {
      required: false,
      default() {
        return ''
      }
    },
    labelWidth: {
      required: false,
      default: 'auto'
    },
    classes: {
      type: String,
      required: false,
      default: ''
    }
  },
}
</script>

<style scoped lang="scss">
  .descriptions-item {
    padding-bottom: 22px;
    padding-right: 20px;
    /*padding-top:9px;*/
    span {
      display: inline-block;
    }
    .descriptions-item-content {
      display: flex;
      justify-content: flex-start;
      /*align-items: center;*/
      color: #606266;
      font-size: 14px;
      line-height: 1.5;
      width: 100%;
      .descriptions-item-label{
        flex-grow: 0;
        flex-shrink: 0;
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height:1.5;
        padding: 0 12px 0 0;
      }
      .descriptions-item-value{
        flex-grow: 1;
        overflow: hidden;
        line-height: 1.5;
        .default-value{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
</style>

/**
 * 管理员优惠券相关 API
 */
import { fetch, post } from 'api/base/axios'

const URL = {
  addUrl: '/coupons/admin/create/',
  updateUrl: '/coupons/admin/update/',
  batchUpdateUrl: '/coupons/admin/update/batch/',
  listUrl: '/coupons/admin/list/',

  updateVipUserUrl: '/coupons/admin/coupons_user/update/',
  listVipUserUrl: '/coupons/admin/coupons_user/list/',
};

export function addCoupons (form) {
  return post(URL.addUrl, form)
}

export function listCoupons (form) {
  return fetch(URL.listUrl, form)
}

export function updateCoupons (form) {
  return post(URL.updateUrl, form)
}

export function batchUpdateCoupons (uuid_in, action) {
  return post(URL.batchUpdateUrl, {
    'uuid_in': uuid_in,
    'action': action
  })
}

export function fmtCouponsInfo (item) {

  let type_text = '';
  switch(item.coupons_type){
    case 'discount': {
      type_text= '折扣';
    } break;
    case 'deducted': {
      type_text= '抵扣';
    } break;
  }
  item.type_text = type_text;

  let cur_scope_text = '';
  switch(item.cur_scope){
    case 'all': {
      cur_scope_text= '全部课程';
    } break;
    case 'assign': {
      cur_scope_text= '指定课程';
    } break;
  }

  let expired_method_text = '';
  switch(item.expired_method){
    case 'days': {
      expired_method_text= '指定天数';
    } break;
    case 'cycle': {
      expired_method_text= '指定周期';
    } break;
  }
  item.expired_method_text = expired_method_text;

  let state_text = '';
  switch (item.state) {
    case -0x1 : {
      state_text = '停用';
    } break;
    case -0x2 : {
      state_text = '过期';
    } break;
    case 1 : {
      state_text = '启用';
    } break;
  }
  item.state_text = state_text;

  let available_num_text = '不限';
  if (item.assets_limit){
    item.assets_limit_text = item.assets_limit;

    let avaliable_num = item.assets_limit - item.used_num;

    if (avaliable_num > 0){
      available_num_text = avaliable_num;
    } else {
      available_num_text = 0;
    }

  } else {
    item.assets_limit_text = '不限';
  }


  let issue_way_text = '';

  switch (item.issue_way) {
    case 1 : {
      issue_way_text = '注册';
    } break;
    case 2 : {
      issue_way_text = '下单';
    } break;
    case 3 : {
      issue_way_text = '课程详情';
    } break;
    case 4 : {
      issue_way_text = '兑换码';
    } break;
  }
  item.issue_way_text = issue_way_text;

  item.available_num = available_num_text;

  let coupons_discount_text = null;
  let coupons_discount_limit_desc = null;
  if (item.coupons_type == 'discount') {
    coupons_discount_text = '折';

    if (item.limit_price > 0) {
      coupons_discount_limit_desc = `最高封顶${item.limit_price}元`
    } else {
      coupons_discount_limit_desc = '无封顶折扣'
    }


  } else if (item.coupons_type == 'deducted') {
    coupons_discount_text = '元';

    if (item.limit_price > 0) {
      coupons_discount_limit_desc = `满${item.limit_price}元可用`
    } else {
      coupons_discount_limit_desc = '无门槛'
    }

  }

  let coupons_scope_detail_text = null;
  let assign_cur_desc_list = [];
  if (item.cur_scope == 'all') {
    coupons_scope_detail_text = '全部课程可用';
  } else if (item.cur_scope == 'assign') {
    coupons_scope_detail_text = '部分课程可用';

     if (item.assign_curs != undefined){
        item.assign_curs.forEach(item=>{
          let cur_info = item.cur_info;
          if(cur_info){
            assign_cur_desc_list.push({'name': cur_info.name, 'price': cur_info.promote_price});
          }
        });
       if (item.single_show && (item.assign_curs.length === 1 || assign_cur_desc_list.length === 1)) {
         coupons_scope_detail_text = '仅该课程可用'
       }

      }

  }

  let coupons_expired_text = null;
  let coupons_expired_detail_text = null;
  let coupons_expired_desc_text = null;
  if (item.expired_method == 'days') {

    coupons_expired_text = `${item.expired_days}天`;
    coupons_expired_detail_text = '';
    coupons_expired_desc_text = `${item.expired_days}天`;

  } else if (item.expired_method == 'cycle') {

    coupons_expired_text = '指定使用周期';
    coupons_expired_detail_text = item.expired_cycle_end_local;
    coupons_expired_desc_text = `${item.expired_cycle_start_local} ~ ${item.expired_cycle_end_local}`

  }

  let user_limit_desc = null;
  if (item.user_limit > 0) {

    if (item.user_issued) {
      user_limit_desc = `每人限领${item.user_limit}张，已领取${item.user_issued}张`;
    } else {
      user_limit_desc = `每人限领${item.user_limit}张`;
    }

  } else {
    user_limit_desc = '每人领取数量不限';
  }


  item.cur_scope_text = cur_scope_text;
  item.discount_text = coupons_discount_text;
  item.discount_limit = coupons_discount_limit_desc;
  item.scope_detail_text = coupons_scope_detail_text;
  item.cur_scope_detail = assign_cur_desc_list;
  item.expired_text = coupons_expired_text;
  item.expired_detail_text = coupons_expired_detail_text;
  item.expired_desc = coupons_expired_desc_text;
  item.user_limit_desc = user_limit_desc;

  return item

}


export function listCouponsUser(form) {
  return fetch(URL.listVipUserUrl, form)
}


export function fmtCouponsUserInfo (item) {

  let state_text = '';
  switch (item.state) {
    case -0x02 : {
      state_text = '已过期';
    } break;
    case 1 : {
      state_text = '未派发';
    } break;
    case 2 : {
      state_text = '已派发';
    } break;
    case 3 : {
      state_text = '已领取';
    } break;
    case 4 : {
      state_text = '已下单';
    } break;
    case 5 : {
      state_text = '已使用';
    } break;
  }
  item.state_text = state_text;

  return item

}

export function batchUpdateCouponsUser (uuid_in, action) {
  return post(URL.updateVipUserUrl, {
    'uuid_in': uuid_in,
    'action': action
  })
}



/**
 * 用户侧优惠券相关 API
 */
import { fetch, post } from 'api/base/axios'

const URL = {
  issueCurUrl: '/coupons/coupons_user/issue_cur/',
  exchangeUrl: '/coupons/coupons_user/exchange/',
  issueUrl: '/coupons/coupons_user/issue/',
  listUrl: '/coupons/coupons_user/list/',
  listCouponStrategyCode: '/coupons/coupons/list/',
};

export function issueCur(coupons_uuid) {
  return fetch(URL.issueCurUrl, {
    'coupons_uuid': coupons_uuid
  })
}

export function exchangeCoupons(exchange_code) {
  return fetch(URL.exchangeUrl, {
    'exchange_code': exchange_code
  })
}

export function issueLink (strategy_code) {
  return fetch(URL.issueUrl, {
    'strategy_code': strategy_code
  })
}

export function listCouonsUser(form) {
  return fetch(URL.listUrl, form)
}


export function getCouponsByStrategyCode(strategy_code) {
  return fetch(URL.listCouponStrategyCode, {
    'strategy_code': strategy_code
  })
}





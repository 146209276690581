import { Dialog, Notify } from 'vant';

//公共确认方法
export function showConfirmMessageBox(message) {
  return Dialog.confirm({
    title: '提示',
    message: message,
  })
}
//公用提示方法
export function showMessage(message) {
  return Dialog.alert({
    title: '提示',
    message: message,
  })
}
//公用通知方法
export function showNotify(message, type) {
  return Notify({
    type: type,
    message: message,
  })
}

/**
 * 管理员课程相关 API
 */
import { fetch, post } from 'api/base/axios'

const URL = {
  listUrl: '/settlement/cur/admin/cur_list/',
  listCatsUrl: '/settlement/cur/admin/cur_cats_list/',
  listPromotesUrl: '/settlement/cur/promotes_list/',
  getVipCardInstancesUrl: '/settlement/cur/vip_card_instance/',
  getCouponsInstancesUrl: '/settlement/cur/coupons_instance/',
};

export function listCur (form) {
  return post(URL.listUrl, form)
}


export function listCurCats(){
  return post(URL.listCatsUrl)
}


export function listPromotes(cur_uuid) {
  return fetch(URL.listPromotesUrl, {
    'cur_uuid': cur_uuid
  })
}


export function listVipCardInstances (form) {
  return post(URL.getVipCardInstancesUrl, form)
}

export function listCouponsInstances (form) {
  return post(URL.getCouponsInstancesUrl, form)
}

/**
 * 卡券颜色相关 API
 */

import { delta_days_text } from 'utils/timeutils'


export function expired_delta_days_conf(expired_time) {

  let delta_days = delta_days_text(expired_time);
  let text_color = '';
  let delta_text = '';

  if (delta_days < 0){
    delta_text = '';
    text_color = '';
  } else if (delta_days >0 && delta_days <= 1) {
    delta_text = '今天即将到期';
    text_color = '#F51C21';
  } else if (delta_days <= 2) {
    delta_text = '明天即将过期';
    text_color = '#F51C21';
  } else {

    if (delta_days > 2 && delta_days <= 5) {
      text_color = '#F59C1B';
    }

    delta_text = `${Math.ceil(delta_days)}天后过期`

  }


  console.log(delta_days, {
    delta_days: Math.ceil(delta_days),
    delta_text: delta_text,
    text_color: text_color
  })

  return {
    delta_days: Math.ceil(delta_days),
    delta_text: delta_text,
    text_color: text_color
  }

}

/**
 * Created by syk on 21-11-25.
 */

import { showMessage, showNotify } from 'api/base/mobile/message'

import {fmtVIPCardInfo} from 'api/settlement/vip_card/admin'
import {fmtCouponsInfo} from 'api/settlement/coupons/admin'
import {fmtOrderInfo} from 'api/settlement/order/admin'

import {listVipUser} from 'api/settlement/vip_card/user'
import {listCouonsUser} from 'api/settlement/coupons/user'

import { db_time_to_local_fmt } from 'utils/timeutils'
import {expired_delta_days_conf} from 'api/settlement/card_util'

import { listVipCardInstances, listCouponsInstances } from 'api/settlement/cur_util'

 export async function getVipUserInstance(vip_user_uuid, before_callback=null, after_callback=null){

        let vip_user_instance = null;

        if (before_callback){
          before_callback();
        }

        await  listVipUser({
          'vip_user_uuid': vip_user_uuid,
          'cur_info': true,
        }).then(result => {

          if (result.succeed) {

            let item = result.data.length > 0 ? result.data[0] : null;

            let filled_item = fmtVIPCardInfo(item.vip_card);
            filled_item.vip_user_uuid = item.uuid;
            filled_item.expired_at = db_time_to_local_fmt(item.rights_end, 'yyyy-MM-dd');
            filled_item.is_enabled = item.is_enabled && filled_item.state_text !== '停用';

            let delta_conf = expired_delta_days_conf(item.rights_end);
            filled_item.expired_days = delta_conf.delta_days;
            filled_item.expired_text = delta_conf.delta_text;
            filled_item.expired_text_color = delta_conf.text_color;

            vip_user_instance = filled_item;

          } else {

            switch (result.error_code) {
              case -9999 : {
                window.location.href = `/mobile/login/?next=/mobile/center/card`;
              }
                break;
              default: {
                showMessage(result.error_msg)
              }
                break;
            }

          }
        });

        if(after_callback){
          after_callback();
        }

        return vip_user_instance;

      }

 export async function getCouponsUserInstance(coupons_user_uuid, before_callback=null, after_callback=null){
        let coupons_user_instance = null;

        if (before_callback) {
           before_callback();
        }

        await listCouonsUser({
          'coupons_user_uuid': coupons_user_uuid,
          'cur_info': true,
        }).then(result => {

          if (result.succeed) {

            let item = result.data.length > 0 ? result.data[0] : null;

            let filled_item = fmtCouponsInfo(item.coupons);
            filled_item.coupons_user_uuid = item.uuid;
            filled_item.expired_at = db_time_to_local_fmt(item.time_end, 'yyyy-MM-dd');
            filled_item.is_enabled = item.is_enabled && filled_item.state_text !== '停用';

            let delta_conf = expired_delta_days_conf(item.time_end);
            filled_item.expired_days = delta_conf.delta_days;
            filled_item.expired_text = delta_conf.delta_text;
            filled_item.expired_text_color = delta_conf.text_color;

            if (item.order_info != null) {
              filled_item.related_order = fmtOrderInfo(item.order_info);
            }

            coupons_user_instance = filled_item

          } else {

            switch (result.error_code) {
              case -9999 : {
                window.location.href = `/mobile/login/?next=/mobile/center/card`;
              } break;
              default: {
                showMessage(result.error_msg)
              } break;
            }

          }


        }).catch(err => {
          showMessage(err)
        });

       if (after_callback) {
         after_callback();
       }

        return coupons_user_instance;

      }


 export async function getVipCardInstance(vip_card_uuid, before_callback=null, after_callback=null){

        let vip_card_instance = null;

        if (before_callback){
          before_callback();
        }

        await  listVipCardInstances({
          'vip_card_uuid': vip_card_uuid,
          'cur_info': true,
        }).then(result => {

          if (result.succeed) {

            let item = result.instance ? result.instance : null;

            let filled_item = fmtVIPCardInfo(item);
            filled_item.vip_user_uuid = item.uuid;
            filled_item.expired_at = db_time_to_local_fmt(item.rights_end, 'yyyy-MM-dd');
            filled_item.is_enabled = item.is_enabled && filled_item.state_text !== '停用';

            let delta_conf = expired_delta_days_conf(item.rights_end);
            filled_item.expired_days = delta_conf.delta_days;
            filled_item.expired_text = delta_conf.delta_text;
            filled_item.expired_text_color = delta_conf.text_color;

            vip_card_instance = filled_item;

          } else {

            showMessage(result.error_msg)
          }
        });

        if(after_callback){
          after_callback();
        }

        return vip_card_instance;

      }

 export async function getCouponsInstance(coupons_uuid, before_callback=null, after_callback=null){
        let coupons_instance = null;

        if (before_callback) {
           before_callback();
        }

        await listCouponsInstances({
          'coupons_uuid': coupons_uuid,
          'cur_info': true,
        }).then(result => {

          if (result.succeed) {

            let item = result.instance ? result.instance : null;

            let filled_item = fmtCouponsInfo(item);
            filled_item.coupons_user_uuid = item.uuid;
            filled_item.expired_at = db_time_to_local_fmt(item.time_end, 'yyyy-MM-dd');
            filled_item.is_enabled = item.is_enabled && filled_item.state_text !== '停用';

            let delta_conf = expired_delta_days_conf(item.time_end);
            filled_item.expired_days = delta_conf.delta_days;
            filled_item.expired_text = delta_conf.delta_text;
            filled_item.expired_text_color = delta_conf.text_color;

            if (item.order_info != null) {
              filled_item.related_order = fmtOrderInfo(item.order_info);
            }

            coupons_instance = filled_item

          } else {

            showMessage(result.error_msg)

          }


        }).catch(err => {
          showMessage(err)
        });

       if (after_callback) {
         after_callback();
       }

        return coupons_instance;

      }

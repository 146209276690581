/**
 * 用户侧会员卡相关 API
 */
import { fetch, post } from 'api/base/axios'

const URL = {
  listUrl: '/vip_card/vip_user/list/',

};

export function listVipUser(form) {
  return fetch(URL.listUrl, form)
}




